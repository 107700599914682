<template>
  <div class="course-resources-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title"> {{ id ? '编辑课程资源' : '添加课程资源' }}</span>
      </div>
      <el-form ref="form" :model="form" label-width="115px" :rules="rules">
        <el-form-item label="素材名称" prop="videoName">
          <el-input v-model.trim="form.videoName" placeholder="请输入素材名称" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item label="主讲讲师" prop="teacherName">
          <el-input
            v-model.trim="form.teacherName"
            placeholder="请输入讲师名称"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item label="素材分组">
          <el-select v-model="form.groupId" placeholder="请选择素材分组" clearable>
            <el-option
              v-for="item in groupList"
              :key="item.groupId"
              :label="item.groupName"
              :value="item.groupId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item ref="coverImageUrl" label="封面图片" prop="coverImageUrl">
          <upload-image
            v-model="form.coverImageUrl"
            drag
            :upload-img="$refs.coverImageUrl"
            :width-size="widthSize"
            :height-size="heightSize"
          ></upload-image>
          <p class="tip">图片大小最好在564 X 290，支持扩展名：.png .jpg .jpeg</p>
        </el-form-item>
        <!-- <el-form-item v-if="!id" label="素材类型">
          <el-radio-group v-model="form.materialType">
            <el-radio :label="1">视频</el-radio>
            <el-radio :label="6">音频</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item v-if="!id" label="上传素材">
          <el-radio-group v-model="uploadType">
            <el-radio-button label="1">本地上传</el-radio-button>
            <!-- <el-radio-button label="2">第三方素材网站</el-radio-button> -->
          </el-radio-group>
          <div v-if="uploadType == 1">
            <el-upload
              action="/api/third/api/huaWeiUpload/upload/video/local"
              :on-success="handleUploadSuccess"
              :before-upload="beforeUpload"
              :file-list="fileList"
              :limit="1"
              accept=".mp4,.Mp4"
            >
              <el-button>选择上传素材</el-button>
              <p slot="tip" class="tip">
                选择素材后，无需等待在这个页面，点击确定后可以查看上传进度
              </p>
            </el-upload>
          </div>
          <div v-if="uploadType == 2">
            <el-form-item label="素材第三方平台">
              <el-select v-model="form.videoPlatform" placeholder="选择视频平台" clearable>
                <el-option label="腾讯云" value="2"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="素材地址">
              <el-input v-model="form.videoUrl" placeholder="请输入素材地址" clearable></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="submit(0)">确定</el-button>
          <el-button
            type="primary"
            :loading="loadingContinue"
            style="margin-left:50px"
            @click="submitContinue()"
            >确定并继续添加</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import {
  courseVideoAdd,
  courseVideoUpdate,
  uploadMetaDataUrl,
  videoInfo,
  groupList,
} from '@/api/course'
import to from 'await-to'
import UploadImage from '@/components/UploadImage'
export default {
  name: 'CourseResourcesAdd',
  components: { UploadImage },
  data() {
    return {
      widthSize: 564,
      heightSize: 290,
      rules: {
        videoName: [
          { required: true, message: '请输入素材名称', trigger: 'blur' },
          // { min: 4, max: 12, message: '请输入4~12个字', trigger: 'blur' },
        ],
        teacherName: [{ required: true, message: '请输入讲师名称', trigger: 'blur' }],
        // coverImageUrl: [{ required: true, message: '请上传封面图片', trigger: 'change' }],
      },
      form: {
        materialType: 1,
        videoPlatform: '2',
        groupName: '',
      },
      loading: false,
      loadingContinue: false,
      uploadType: 1,
      fileList: [],
      id: '',
      groupList: [],
    }
  },
  mounted() {
    this.groupListData()
    const { id } = this.$route.params
    if (id) {
      this.id = id
      this.getVideoInfo()
    }
  },
  methods: {
    async getVideoInfo() {
      const [res, err] = await to(videoInfo({ id: this.id }))
      if (err) return this.$message.warning(err.msg)
      this.form = res.data
    },
    async groupListData() {
      const [res, err] = await to(groupList({ groupName: '' }))
      if (err) return this.$message.warning(err.msg)
      this.groupList = res.data
    },
    beforeUpload(file) {
      const isMP4 = file.type === 'video/mp4'
      if (!isMP4) {
        this.$message.error('上传视频只能是 MP4 格式!')
      }
      return isMP4
    },
    handleUploadSuccess(response) {
      this.form.videoId = response.data.assetId
      this.$message.success('上传成功')
    },
    async submit(status) {
      if (this.id) {
        this.update(status)
      } else {
        if (this.uploadType == 2) {
          await this.urlUpload()
        }
        this.add(status)
      }
    },
    submitContinue() {
      this.submit(1)
    },
    async urlUpload() {
      if (!this.form.videoUrl) {
        return this.$message.warning('请输入素材地址')
      }
      const res = await uploadMetaDataUrl({
        title: this.form.videoName,
        type: '1',
        url: this.form.videoUrl,
      })
      if (res.data.httpCode != 200) return this.$message.warning(res.data.errorMsg)
      // if (err) return this.$message.warning(err.msg)
      this.form.videoId = res.data.uploadAssetList[0].assetId
    },
    add(status) {
      if (this.uploadType == 1 && !this.form.videoId) {
        return this.$message.warning('请上传素材')
      }
      if (
        (this.uploadType == 2 && !this.form.videoUrl) ||
        (this.uploadType == 2 && !this.form.videoId)
      ) {
        return
      }
      this.$refs['form'].validate(async valid => {
        if (valid) {
          if (status == 1) {
            this.loadingContinue = true
          } else {
            this.loading = true
          }
          this.groupList.map(v => {
            if (v.groupId == this.form.groupId) {
              this.form.groupName = v.groupName
            }
          })
          const [, err] = await to(courseVideoAdd(this.form))
          this.loading = false
          this.loadingContinue = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('添加成功')
          if (status == 1) {
            this.$router.push('/course/resources/add')
            return (this.form = { materialType: 1, videoPlatform: '2' }), (this.fileList = [])
          } else {
            this.$router.push('/course/resources')
          }
        }
      })
    },
    update(status) {
      if (this.uploadType == 1 && !this.form.videoId && !this.id) {
        return this.$message.warning('请上传素材')
      }
      if (
        (this.uploadType == 2 && !this.form.videoUrl) ||
        (this.uploadType == 2 && !this.form.videoId)
      ) {
        return
      }
      this.$refs['form'].validate(async valid => {
        if (valid) {
          if (status == 1) {
            this.loadingContinue = true
          } else {
            this.loading = true
          }
          this.groupList.map(v => {
            if (v.groupId == this.form.groupId) {
              this.form.groupName = v.groupName
            }
          })
          const [, err] = await to(courseVideoUpdate({ id: this.id, ...this.form }))
          this.loading = false
          this.loadingContinue = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('编辑成功')
          if (status == 1) {
            this.form = { materialType: 1, videoPlatform: '2' }
            this.fileList = []
            this.$router.push('/course/resources/add')
          } else {
            this.$router.push('/course/resources')
          }
          // return (this.form = { materialType: 1, videoPlatform: '2' }), (this.fileList = [])
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.course-resources-add {
  .el-radio-button {
    margin-bottom: 20px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep.uploader {
    height: 92px;
    .el-upload {
      width: 180px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 180px;
      height: 100%;
    }
  }
}
</style>
